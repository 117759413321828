<template>
  <CRow>
    <CCol lg="12">
      <CCard>
        <ValidationObserver ref="form">
          <CCardHeader tag="div">
            <CIcon name="cil-grid"/>
            Chi tiết đề xuất chương trình xúc tiến đầu tư quốc gia
          </CCardHeader>
          <CCardBody>
            <CForm>
              <div class="w-100">
                <div class="pdf-wrapper">
                  <div class="modal-body modal-container">
                    <div class="pdf-page">
                      <div class="fs-16">
                        <div id="pdf1" class="pdf-content ml-3 mr-3">
                          <div class="text-center fs-16">
                            <strong class="bold">Mẫu C.I.1</strong><br>
                            <strong>Văn bản đề xuất thực hiện chương trình xúc tiến đầu tư quốc gia hàng
                              năm</strong><br>
                            <p class="font-italic">(Điểm b, Khoản 2, Điều 92 Nghị định số 31/2021/NĐ-CP)</p>
                            <hr>
                          </div>
                          <div class="row fs-16">
                            <div class="col-6">
                              <ValidationProvider name="Tên cơ quan chủ trì" rules="required"
                                                  v-slot="{ errors, valid , touched }">
                                <div role="group" class="form-group form-row">
                                  <div class="col-sm-9">
                                    <input id="coQuanChuTri" type="text" class="form-control"
                                           placeholder="Tên Bộ/UBND Tỉnh"
                                           :class="{ 'is-valid': valid === true, 'is-invalid': touched && valid === false }"
                                           v-model="detailItem.coQuanChuTri"/>
                                    <div class="invalid-feedback" v-if="!valid">
                                      <ul>
                                        <li v-for="e in errors" :key="e">{{ e }}</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </ValidationProvider>
                            </div>
                            <div class="col-6"></div>
                            <div class="col-md-6">
                              <!--                        <CInput placeholder="Tên Bộ/UBND Tỉnh" horizontal :value.sync="item.coQuanChuTri"/>-->
                              <ValidationProvider name="Số văn bản" rules="required"
                                                  v-slot="{ errors, valid , touched }">
                                <div role="group" class="form-group form-row">
                                  <div class="col-sm-9">
                                    <input id="soVanBan" type="text" class="form-control"
                                           placeholder="Số:"
                                           :class="{ 'is-valid': valid === true, 'is-invalid': touched && valid === false }"
                                           v-model="detailItem.soVanBan"/>
                                    <div class="invalid-feedback" v-if="!valid">
                                      <ul>
                                        <li v-for="e in errors" :key="e">{{ e }}</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </ValidationProvider>
                              <!--                        <CInput placeholder="Số:" horizontal :value.sync="item.soVanBan"/>-->
                              <p class="font-weight">V/v dự kiến chương trình đầu tư năm
                                {{ detailItem.nam_ChuongTrinh = Number(getYear(detailItem.ngayVanBan)) + 1 }}</p>
                            </div>
                            <div class="col-md-6">
                              <p class="text-center text-uppercase font-weight-bold">Cộng hòa xã hội chủ nghĩa Việt
                                Nam</p>
                              <p class="text-center font-weight-bold">Độc lập – Tự do – Hạnh phúc</p>
                              <p class="font-weight font-italic" style="float: right; display: flex">
                                <ValidationProvider name="Địa điểm" rules="required"
                                                    v-slot="{ errors, valid , touched }">
                              <div role="group" class="form-group form-row">
                                <div class="col-sm-9">
                                  <input id="diaDiem" type="text" class="form-control"
                                         placeholder="Địa điểm"
                                         :class="{ 'is-valid': valid === true, 'is-invalid': touched && valid === false }"
                                         v-model="detailItem.diaDiem"/>
                                  <div class="invalid-feedback" v-if="!valid">
                                    <ul>
                                      <li v-for="e in errors" :key="e">{{ e }}</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              </ValidationProvider>
                              <!--                          <CInput placeholder="Địa điểm:" horizontal :value.sync="item.diaDiem"/>-->
                              <span class="mt-2">, ngày {{
                                  getDay(detailItem.ngayVanBan)
                                }} tháng {{
                                  getMonth(detailItem.ngayVanBan)
                                }} năm {{ getYear(detailItem.ngayVanBan) }}</span>
                              </p>
                            </div>
                          </div>
                          <div class="text-center">
                            <p>Kính gửi: Bộ Kế hoạch và Đầu tư</p>
                          </div>
                          <div>
                            <div class="col-12">
                              <p style="text-indent: 2em">Căn cứ Điều ... Luật Đầu tư, Điều ... Nghị định số
                                31/2021/NĐ-CP ngày
                                26/3/2021, Thông tư số .. /2021/TT-BKHĐT ngày ... quy định biểu mẫu thực hiện hoạt động
                                đầu tư tại Việt Nam, đầu tư tại Việt Nam, đầu tư từ Việt Nam ra nước ngoài và xúc tiến
                                đầu tư và hướng dẫn của Bộ Kế hoạch và Đầu tư tại công văn số .. ngày ...,
                                {{ detailItem.coQuanChuTri }} xin báo cáo và đề xuất một số nội dung như sau:</p>
                              <p style="text-indent: 2em" class="font-italic">(Trong văn bản phải đề cập các nội dung:
                                đánh giá sơ bộ kết quả hoạt động xúc tiến đầu tư năm trước; điều kiện, xu thế và bối
                                cảnh gắn với tiềm năng của vùng, lãnh thổ; sự cần thiết, tác động, ý nghĩa của các hoạt
                                động đối với liên kết vùng. Từ đó, nêu những lĩnh vực, đối tác cần hướng tới...)</p>
                              <ValidationProvider name="Đánh giá hoạt động năm trước" rules="required"
                                                  v-slot="{ errors, valid , touched }">
                                <div role="group" class="form-group form-row">
                                  <div class="col-12">
                              <textarea id="danhGiaHoatDongNamTruoc" type="text" class="form-control" :rows="1"
                                        placeholder="Nhập đánh giá"
                                        :class="{ 'is-valid': valid === true, 'is-invalid': touched && valid === false }"
                                        v-model="detailItem.danhGiaHoatDongNamTruoc"/>
                                    <div class="invalid-feedback" v-if="!valid">
                                      <ul>
                                        <li v-for="e in errors" :key="e">{{ e }}</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </ValidationProvider>
                            </div>
                            <div class="col-12">
                              <div style="display: flex">
                                <p style="text-indent: 2em;">Do đó, cần phải thực hiện các hoạt động đề xuất</p>
                                <ValidationProvider name="Nội dung đề xuất" rules="required"
                                                    v-slot="{ errors, valid , touched }">
                                  <div role="group" class="form-group form-row">
                                    <div class="col-12 ml-3">
                                      <textarea id="noiDung" type="text" class="form-control" :rows="1"
                                                placeholder="Nhập nội dung"
                                                :class="{ 'is-valid': valid === true, 'is-invalid': touched && valid === false }"
                                                v-model="detailItem.noiDung"/>
                                      <div class="invalid-feedback" v-if="!valid">
                                        <ul>
                                          <li v-for="e in errors" :key="e">{{ e }}</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </ValidationProvider>
                              </div>
                              <div style="display: flex">
                                <p>hướng tới ... <span class="font-italic">(đạt mục tiêu, kết hợp ngân sách kèm xã hội hóa...)</span></p>
                                <ValidationProvider name="Mục tiêu" rules="required"
                                                    v-slot="{ errors, valid , touched }">
                                  <div role="group" class="form-group form-row">
                                    <div class="col-12 ml-3">
                                      <textarea id="mucTieu" type="text" class="form-control" :rows="1"
                                                placeholder="Nhập mục tiêu"
                                                :class="{ 'is-valid': valid === true, 'is-invalid': touched && valid === false }"
                                                v-model="detailItem.mucTieu"/>
                                      <div class="invalid-feedback" v-if="!valid">
                                        <ul>
                                          <li v-for="e in errors" :key="e">{{ e }}</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </ValidationProvider>
                              </div>
                              <div style="display: flex">
                                <p style="text-indent: 2em">{{ detailItem.coQuanChuTri }} đề nghị Bộ Kế hoạch và Đầu tư đưa {{ itemEmit.soLuong | formatNumber}}
                                  <span class="font-italic"> (số lượng)</span> hoạt động xúc tiến đầu tư vào</p>
                              </div>
                              <div style="display: flex">
                                <p>chương trình xúc tiến đầu tư quốc gia năm {{ detailItem.nam_ChuongTrinh }} với tổng kinh phí đề xuất: {{ itemEmit.tongKinhPhi | formatNumber}} triệu đồng, trong đó:</p>
                              </div>
                              <div style="display: flex">
                                <p>{{ itemEmit.kinhPhi_ChuongTrinhXTDTQG | formatNumber}} triệu đồng, từ nguồn kinh phí của Chương trình xúc tiến đầu tư quốc gia.</p>
                              </div>
                            </div>
                          </div>
                          <div>
                            <p style="text-indent: 2em">{{ detailItem.coQuanChuTri }} xin gửi kèm theo các tài liệu:</p>
                            <div class="col-12 row">
                              <div class="col">
                                <p>1. Biểu tổng hợp đề xuất thực hiện chương trình xúc tiến đầu tư quốc gia;</p>
                                <p>2. Đề án của từng hoạt động xúc tiến đầu tư;</p>
                                <p>3. Bảng dự toán kinh phí của từng hoạt động xúc tiến đầu tư đề xuất.</p>
                              </div>
                              <div class="col">
                                <CButton class="mt-3" color="primary"
                                         @click="toggleDetailPhuLucModal({show:true})">
                                  <i name="cil-highlighter"/> Mẫu C.I.2
                                </CButton>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div class="col-12 row">
                              <div class="col">
                                <div class="float-left">
                                  <span class="font-italic font-weight-bold fs-18">Nơi nhận:</span> <br>
                                  <span class="font-weight">- Như trên;</span> <br>
                                  <span class="font-weight">- Lưu: VT, ...</span> <br>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 row">
                              <div class="col"></div>
                              <div class="col text-center">
                                <span class="font-weight-bold text-uppercase">Thủ trưởng cơ quan chủ trì</span> <br>
                                <span class="font-weight font-italic">(Ký, ghi rõ họ tên, chức danh và đóng dấu)</span>
                                <br>
                                <br>
                                <br>
                              </div>
                            </div>
                          </div>
                          <div class="form-group row mb-0 mt-3">
                            <div class="col-12">
                              <b class="mt-5">Đính kèm file</b>
                              <div class="import-files mb-2" v-if="Attachments.length">
                                <div class="files mb-1" v-for="file in Attachments" v-bind:key="file.id">
                                  <a :href="getDownloadURL(file.url)" target="_blank">{{ file.fileName }}</a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-xl-12" v-if="yKienGanNhat.dinhKem">
                            <label>Ý kiến phê duyệt gần nhất: <span class="font-weight-bold">{{ yKienGanNhat.noiDung}}</span></label>
                            <div class="import-files mb-2">
                              <div class="files mb-1" v-for="file in JSON.parse(yKienGanNhat.dinhKem)" v-bind:key="file.id">
                                <a :href="getDownloadURL(file.url)" target="_blank">{{ file.fileName }}</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CForm>
            <CElementCover v-if="isLoading"/>
            <DeXuatCTXTDTQGPhuLucDetailModal :item="detailItem"/>
          </CCardBody>
          <CCardFooter tag="div" class="text-right">
            <CButton color="secondary" class="mr-2" @click="back">Quay lại</CButton>
            <CButton color="ghost" class="mr-2" @click="print">Xuất văn bản</CButton>
<!--            <CButton color="danger" @click="isDeleteConfirmationModalOpen = true">Xóa</CButton>-->
          </CCardFooter>
        </ValidationObserver>
      </CCard>
    </CCol>
<!--    <DeleteConfirmationModal-->
<!--      title="Xóa Đề xuất Chương trình Xúc tiến đầu tư này?"-->
<!--      :show.sync="isDeleteConfirmationModalOpen"-->
<!--      :is-deleting="isDeleting"-->
<!--      :item="item" @confirm-destroy="confirmDestroy"/>-->
  </CRow>
</template>

<script>
import { statuses, loaiDonVis } from '@/shared/appConstants'
import { mapGetters, mapActions } from 'vuex'
import {
  DELETE_DEXUATCTXTDTQUOCGIA,
  GET_DEXUATCTXTDTQUOCGIA, TOGGLE_DETAIL_MODAL
} from '@/store/modules/DeXuatCTXTDTQG/actionTypes'
import { tinhThanhService } from '@/services/tinh-thanh.service'
import { nganhLinhVucService } from '@/services/nganh-linh-vuc.service'
import { quocGiaService } from '@/services/quoc-gia.service'
import moment from 'moment'
import DeXuatCTXTDTQGPhuLucDetailModal from '@/components/DeXuatCTXTDTQG/DeXuatCTXTDTQGPhuLucDetailModal'
import appConfig from '@/shared/appConfig'
import { GET_YKIENPHEDUYET, GET_YKIENPHEDUYETGANNHAT } from '@/store/modules/VanBanTBKHTCHDXTHHDTTMDLNGKT/actionTypes'

export default {
  name: 'DeXuatCTXTDTQGDetail',
  components: {
    DeXuatCTXTDTQGPhuLucDetailModal
  },
  data () {
    return {
      invalid: true,
      Attachments: [],
      type: 'DeXuatCTXTDTQuocGia',
      isLoading: false,
      optionsTrangThai: statuses,
      optionsLoaiDonVi: loaiDonVis,
      isDeleteConfirmationModalOpen: false,
      optionsTinhThanh: [],
      optionsQuocGia: [],
      optionsNganhLinhVuc: [],
      item: this.detailItem,
      itemEmit: {
        soLuong: 0,
        tongKinhPhi: 0,
        kinhPhi_ChuongTrinhXTDTQG: 0
      },
      yKienGanNhat: {
        noiDung: '',
        dinhKem: ''
      }
    }
  },
  computed: {
    ...mapGetters('deXuatCTXTDTQG', {
      detailItem: 'detailItem',
      isFetchingSingle: 'isFetchingSingle',
      isDeleting: 'isDeleting',
      yKienPheDuyetGanNhat: 'yKienPheDuyetGanNhat',
      yKienPheDuyet: 'yKienPheDuyet',
      responseResult: 'responseResult'
    })
  },
  methods: {
    ...mapActions('deXuatCTXTDTQG', {
      getItem: GET_DEXUATCTXTDTQUOCGIA,
      deleteItem: DELETE_DEXUATCTXTDTQUOCGIA,
      toggleDetailPhuLucModal: TOGGLE_DETAIL_MODAL,
      getYKienPheDuyet: GET_YKIENPHEDUYET,
      getYKienPheDuyetGanNhat: GET_YKIENPHEDUYETGANNHAT
    }),
    print () {
      const data = {
        item: this.detailItem,
        itemEmit: this.itemEmit
      }
      localStorage.setItem('de_xuat_ct_xtdt_quoc_gia', JSON.stringify(data))
      const routeData = this.$router.resolve({ path: '/de-xuat-ct-xtdt-qg/bieu-in' })
      window.open(routeData.href, '_blank')
    },
    back () {
      this.$router.push({ path: '/de-xuat-ct-xtdt-qg' })
    },
    editItem () {
      this.$router.push({ path: `/de-xuat-ct-xtdt-qg/${this.detailItem.id}/cap-nhat` })
    },
    async confirmDestroy () {
      await this.deleteItem(this.detailItem)
      if (this.responseResult.error) {
        this.$toast.error(this.responseResult.message)
      } else {
        this.$toast.success(this.responseResult.message)
        this.isDeleteConfirmationModalOpen = false
        await this.$router.push({ path: '/de-xuat-ct-xtdt-qg' })
      }
    },
    countData () {
      let countSoLuong = 0
      let countTongKinhPhi = 0
      let countKinhPhiCTXTDTQG = 0
      for (const i in this.detailItem.chiTietDeXuatCTXTDTQuocGia) {
        countKinhPhiCTXTDTQG += this.detailItem.chiTietDeXuatCTXTDTQuocGia[i].kinhPhi_ChuongTrinhXTDTQG
        countTongKinhPhi += this.detailItem.chiTietDeXuatCTXTDTQuocGia[i].kinhPhi_ChuongTrinhXTDTQG + this.detailItem.chiTietDeXuatCTXTDTQuocGia[i].kinhPhi_NganSachDiaPhuong + this.detailItem.chiTietDeXuatCTXTDTQuocGia[i].kinhPhi_Khac
        countSoLuong++
      }
      this.itemEmit.soLuong = countSoLuong
      this.itemEmit.tongKinhPhi = countTongKinhPhi
      this.itemEmit.kinhPhi_ChuongTrinhXTDTQG = countKinhPhiCTXTDTQG
    },
    getDownloadURL (url) {
      return url && (url.indexOf('http://') > -1 || url.indexOf('https://') > -1) ? url : appConfig.BASE_HOST + url
    },
    getDay: (time, format = 'YYYY-MM-DD') => time ? moment(time, format).format('DD') : '',
    getMonth: (time, format = 'YYYY-MM-DD') => time ? moment(time, format).format('MM') : '',
    getYear: (time, format = 'YYYY-MM-DD') => time ? moment(time, format).format('YYYY') : ''
  },
  async mounted () {
    await this.getItem(this.$route.params.id)
    if (this.detailItem.trangThai === 3) {
      await this.getYKienPheDuyetGanNhat(this.$route.params.id)
      this.yKienGanNhat = this.yKienPheDuyetGanNhat
    }
    if (this.detailItem.dinhKem) this.Attachments = JSON.parse(this.detailItem.dinhKem)
    this.optionsTinhThanh = await tinhThanhService.getAll()
    this.optionsNganhLinhVuc = await nganhLinhVucService.getAll()
    this.optionsQuocGia = await quocGiaService.getAll()
    this.countData()
  }
}
</script>

<style scoped>

</style>
